import type { FC, PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { RenderMode } from '@oneaudi/render-mode-service';
import { MoxxCarlineGroup } from '../../@types/MoxxTypes';
import type { ContextProviderProps, ContextState } from './ContextTypes';
import type { Content } from '../../@types/EditorContentTypes';
import type { I18nMessages } from '../../@types/FeatureAppTypes';
import type { CarlineGroup } from '../../web/generated/onegraph';
import { mapHeadlessContent } from '../utils/mapHeadlessContent';

let i18nMessages!: I18nMessages;
export const Context = createContext<ContextState>({} as ContextState);

const hasShowBasePrice = (content: Content) => content.priceDisplayOption.showBasePrice === true;

const hasShowVat = (content: Content) => content.priceDisplayOption.showVat === true;

const hasShowCaeData = (content: Content) => content.caeDisplayOption.showCae === true;

const hasShowConfigureCTA = (content: Content) =>
  content.ctaDisplayOptions.showConfigureCTA === true;

const hasShowNewAndUsedCarsCTA = (content: Content) =>
  content.ctaDisplayOptions.showNewAndUsedCarsCTA === true;

export function filterMockedAndEmptyCarlineGroups(carlineGroups: CarlineGroup[]): CarlineGroup[] {
  const noEmptyCarlineGroups = carlineGroups.filter(({ carlines }) => carlines.length > 0);

  return noEmptyCarlineGroups;
}

export const ContextProvider: FC<ContextProviderProps> = ({
  featureAppId,
  localeService,
  logger,
  trackingService,
  children,
  i18nService,
  contentService,
  renderModeService,
  numberFormatterService,
}: PropsWithChildren<ContextProviderProps>) => {
  const content = useMemo(() => {
    const originalContent = contentService.getContent();
    if ('__type' in originalContent && originalContent.__type === 'aem-headless') {
      const mappedContent = mapHeadlessContent(originalContent);
      logger?.info('Feature App created with headless content:', mappedContent);
      return mappedContent;
    }
    logger?.info('Feature App createdwith FACE content:', originalContent);
    return originalContent;
  }, []);

  const [isEditMode] = useState<boolean>(renderModeService?.getRenderMode() === RenderMode.EDIT);
  const [showBasePrice, setShowBasePrice] = useState<boolean>(hasShowBasePrice(content));
  const [showVat, setShowVat] = useState<boolean>(hasShowVat(content));

  const [showCaeData, setShowCaeData] = useState<boolean>(hasShowCaeData(content));
  const [showConfigureCTA, setShowConfigureCTA] = useState<boolean>(hasShowConfigureCTA(content));
  const [showNewAndUsedCarsCTA, setShowNewAndUsedCarsCTA] = useState<boolean>(
    hasShowNewAndUsedCarsCTA(content),
  );
  const [priceFootnotes, setPriceFootnotes] = useState<string[] | undefined>(
    content.priceFootnotes,
  );
  const [selectedCarGroupId, setSelectedCarGroupId] = useState<string | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredCarlineGroups, setFilteredCarlineGroups] = useState<
    MoxxCarlineGroup[] | undefined
  >(undefined);
  const [selectedBodyType, setSelectedBodyType] = useState<string | undefined>(undefined);
  const [selectedVehicleType, setSelectedVehicleType] = useState<string | undefined>(undefined);

  useEffect(() => {
    let mounted = true;

    const contentChangeListener = () => {
      if (mounted) {
        const newInitialContent = contentService.getContent();
        const newContent = mapHeadlessContent(newInitialContent);
        setShowBasePrice(hasShowBasePrice(newContent));
        setShowVat(hasShowVat(newContent));
        setShowCaeData(hasShowCaeData(newContent));
        setShowConfigureCTA(hasShowConfigureCTA(newContent));
        setShowNewAndUsedCarsCTA(hasShowNewAndUsedCarsCTA(newContent));
        setPriceFootnotes(newContent.priceFootnotes);
      }
    };
    contentService.onContentChange(contentChangeListener);

    return () => {
      mounted = false;
      contentService.removeOnContentChange(contentChangeListener);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      featureAppId,
      i18nService,
      i18nMessages,
      modalOpenState: [
        isModalOpen,
        { openModal: () => setIsModalOpen(true), closeModal: () => setIsModalOpen(false) },
      ] as const,
      logger,
      trackingService,
      isEditMode,
      localeService,
      numberFormatterService,
      selectedCarGroupId,
      setSelectedCarGroupId,
      filteredCarlineGroups,
      setFilteredCarlineGroups,
      selectedBodyType,
      setSelectedBodyType,
      selectedVehicleType,
      setSelectedVehicleType,
      showBasePrice,
      showVat,
      showCaeData,
      showConfigureCTA,
      showNewAndUsedCarsCTA,
      priceFootnotes,
    }),
    [
      featureAppId,
      i18nService,
      i18nMessages,
      isModalOpen,
      logger,
      trackingService,
      isEditMode,
      localeService,
      numberFormatterService,
      selectedCarGroupId,
      setSelectedCarGroupId,
      selectedVehicleType,
      setSelectedVehicleType,
      filteredCarlineGroups,
      setFilteredCarlineGroups,
      selectedBodyType,
      setSelectedBodyType,
      showBasePrice,
      showVat,
      showCaeData,
      showConfigureCTA,
      showNewAndUsedCarsCTA,
      priceFootnotes,
    ],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
export const usePriceFootnotes = () => useContext(Context).priceFootnotes;
export const useFeatureAppId = () => useContext(Context).featureAppId;
export const useLogger = () => useContext(Context).logger;
export const isEditMode = () => useContext(Context).isEditMode;
export const useI18nService = () => useContext(Context).i18nService;
export const useTrackingService = () => useContext(Context).trackingService;
export const useModalOpenState = () => useContext(Context).modalOpenState;
export const useNumberFormatter = (nr: number) =>
  useContext(Context).numberFormatterService.formatNumber(nr);

export const useCurrencyFormatter = (nr: number) =>
  useContext(Context).numberFormatterService.formatCurrency(nr);

export const useAppContext = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
