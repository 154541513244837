import type { Logger } from '@feature-hub/core';
import type { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { I18NServiceV1 } from '@oneaudi/i18n-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { getCarlineIdsWithoutDiscoverLinks } from './getCarlineIdsWithoutDiscoverLinks';
import {
  VehicleType,
  type CarlineGroup,
  type CarlineStructure,
} from '../../web/generated/onegraph';
import type { I18nMessages, InitialState } from '../../@types/FeatureAppTypes';
import type { MoxxCarline } from '../../@types/MoxxTypes';
import { filterMockedAndEmptyCarlineGroups } from '../context/Context';
import { fetchI18nMessages, matchI18nCategories } from '../i18n';
import { createAsyncCarlineFetch } from './getCarlines';
import { mapToMoxxCarlineGroup } from './mapToMoxxCarlineGroup';

let i18nMessages: I18nMessages;
let categoriesI18nMessages: Record<string, string>;

export async function createInitialState(
  oneGraphService: OneGraphServiceV1,
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
  logger?: Logger,
): Promise<InitialState> {
  const [i18n, categoriesI18n] = await fetchI18nMessages(localeService, i18nService);
  i18nMessages = i18n;
  categoriesI18nMessages = categoriesI18n;

  const fetchedCarlines: CarlineStructure = await createAsyncCarlineFetch(
    oneGraphService,
    localeService,
  );

  const carlineGroups = filterMockedAndEmptyCarlineGroups(fetchedCarlines.carlineGroups).map(
    (carlineGroup: CarlineGroup) => mapToMoxxCarlineGroup(carlineGroup),
  );

  const bodyTypesMap = new Map<string, string>();

  carlineGroups.forEach(({ carlines }) => {
    carlines.forEach((carline) => {
      carline.bodyTypes.forEach((b) => {
        if (!bodyTypesMap.has(b)) {
          bodyTypesMap.set(b, matchI18nCategories(b, categoriesI18nMessages));
        }
      });
    });
  });

  const availableVehicleTypes: VehicleType[] = Array.from(
    new Set(
      carlineGroups.flatMap((carGroup) =>
        carGroup.carlines
          .map((carline) => carline.vehicleType)
          .filter((vehicleType): vehicleType is VehicleType => vehicleType !== undefined),
      ),
    ),
  );

  const carlineIdsWithoutDiscoverLinks = getCarlineIdsWithoutDiscoverLinks(carlineGroups);
  if (carlineIdsWithoutDiscoverLinks.length > 0) {
    logger?.error(
      `There are carlines without discover link attached. The feature app will still render, but this is an error on the data side that needs to be fixed. Affected carline ids:`,
      carlineIdsWithoutDiscoverLinks,
    );
  }

  const bodyTypes = Array.from(bodyTypesMap.entries());

  return {
    carlineGroups,
    bodyTypes,
    availableVehicleTypes,
    i18nMessages,
  };
}

/**
 * Helper function to deserialize the state of the feature app. It converts serialized
 * ReactNodeArray Entries that aren't string to FootnoteReference components
 * @param state
 */

export function deserializeState(state: string): InitialState {
  const props = JSON.parse(state);

  return {
    ...props,
  };
}

export function getStockAmount(carline: MoxxCarline) {
  const availableCarsInStock = carline.availableStock;

  let carsInStock = 0;
  if (availableCarsInStock?.newCars && availableCarsInStock?.newCars?.amount) {
    carsInStock += availableCarsInStock.newCars.amount;
  }
  if (availableCarsInStock?.usedCars && availableCarsInStock.usedCars?.amount) {
    carsInStock += availableCarsInStock.usedCars.amount;
  }
  return carsInStock;
}
