import React from 'react';
import styled from 'styled-components';

import { Text } from '@audi/audi-ui-react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
} from '@oneaudi/feature-app-utils';

interface Consumption {
  consumption?: string;
  emission?: string;
  co2Class?: string;
  dischargedCO2Class?: string;
  dischargedConsumption?: string;
}

interface ConsumptionAndEmissionProps {
  consumptionData: Consumption;
  carlineName: string;
}

const ConsumptionAndEmission: React.FC<ConsumptionAndEmissionProps> = ({
  consumptionData,
  carlineName,
}) => {
  const { consumption, emission, co2Class, dischargedCO2Class, dischargedConsumption } =
    consumptionData;

  if (!consumption && !emission) {
    console.warn('No emission and consumption data for', carlineName);
    return null;
  }

  return (
    <StyledTextWithFootnotes variant="copy2">
      {consumption && (
        <>{renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(consumption))}</>
      )}
      {emission && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(emission))}
        </>
      )}
      {/* footnote for co2Class will be provided, then please use renderTextWithFootnoteReferencesV2 */}
      {co2Class && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(co2Class))}
        </>
      )}
      {dischargedConsumption && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(dischargedConsumption),
          )}
        </>
      )}
      {dischargedCO2Class && (
        <>
          {'; '}
          {renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(dischargedCO2Class),
          )}
        </>
      )}
    </StyledTextWithFootnotes>
  );
};

export const StyledTextWithFootnotes = styled(Text)`
  > sup {
    vertical-align: baseline;
    position: relative;
    top: -1em;
  }
`;

export default ConsumptionAndEmission;
