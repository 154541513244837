/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import { Text, Icon, Select } from '@audi/audi-ui-react';
import { useMediaQuery } from '@oneaudi/feature-app-utils';
import {
  FilterWrapper,
  MobileFilterContainer,
  TagGroupContainer,
  TagContainer,
  ContentWrapper,
} from './FilterStyles';
import { useInitialState } from '../../context/InitialStateContext';
import { useAppContext } from '../../context/Context';
import { MoxxCarlineGroup } from '../../../@types/MoxxTypes';
import { useTrackingManager } from '../../context/useTrackingManager';
import { VehicleType } from '../../../web/generated/onegraph';
import { useI18n } from '../../i18n';

export const Filter = () => {
  const { bodyTypes, i18nMessages } = useInitialState();
  const { trackTagClick } = useTrackingManager();
  const {
    selectedBodyType,
    setSelectedBodyType,
    selectedVehicleType,
    setSelectedVehicleType,
    setFilteredCarlineGroups,
    setSelectedCarGroupId,
    selectedCarGroupId,
    logger,
  } = useAppContext();

  const { carlineGroups: unfilteredCarlineGroups, availableVehicleTypes } = useInitialState();

  const truncateString = (inputString: string | undefined): string => {
    const maxLength = 20;
    if (!inputString) return '';

    if (inputString.length <= maxLength) {
      return inputString;
    }
    return `${inputString.slice(0, maxLength - 3)}...`;
  };

  const handleFilterChange = (
    filterType: 'bodyType' | 'vehicleType',
    value: string | undefined,
  ) => {
    if (filterType === 'bodyType' && setSelectedBodyType && setSelectedVehicleType) {
      setSelectedVehicleType(undefined);
      setSelectedBodyType((prevSelected) => (prevSelected === value ? undefined : value));
    } else if (filterType === 'vehicleType' && setSelectedVehicleType && setSelectedBodyType) {
      setSelectedBodyType(undefined);
      setSelectedVehicleType((prevSelected) => (prevSelected === value ? undefined : value));
    } else {
      logger?.warn('Filter change handler received an undefined setter function');
    }
  };

  const resetFilter = () => {
    if (setSelectedBodyType) {
      setSelectedBodyType(undefined);
    }
    if (setSelectedVehicleType) {
      setSelectedVehicleType(undefined);
    }
    if (setFilteredCarlineGroups) {
      setFilteredCarlineGroups(unfilteredCarlineGroups);
    }
  };

  const filteredCarlineGroups = useMemo(() => {
    return unfilteredCarlineGroups
      .map((carGroup) => {
        let matchingCarlines = carGroup.carlines;

        // Filter by body type if selected
        if (selectedBodyType) {
          matchingCarlines = matchingCarlines.filter((carline) =>
            carline.bodyTypes.includes(selectedBodyType),
          );
        }

        // Filter by vehicle type if selected
        if (selectedVehicleType) {
          matchingCarlines = matchingCarlines.filter(
            (carline) => carline.vehicleType === selectedVehicleType,
          );
        }

        return matchingCarlines.length > 0 ? { ...carGroup, carlines: matchingCarlines } : null;
      })
      .filter((group): group is MoxxCarlineGroup => group !== null);
  }, [selectedBodyType, selectedVehicleType, unfilteredCarlineGroups]);

  // Collapse carline group in case its not included in next filter
  useEffect(() => {
    if (!selectedCarGroupId || !setSelectedCarGroupId) return;

    const filteredCarGroupIds = filteredCarlineGroups.map((group) => group.id);
    if (!filteredCarGroupIds.includes(selectedCarGroupId)) {
      setSelectedCarGroupId(undefined);
    }
  }, [filteredCarlineGroups]);

  // Update context
  useEffect(() => {
    setFilteredCarlineGroups?.(filteredCarlineGroups);
  }, [filteredCarlineGroups]);

  const electricTag = useI18n('electricFilter');
  const hybridTag = useI18n('hybridFilter');
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    return (
      <FilterWrapper>
        <MobileFilterContainer>
          <Select
            inputId="filterType"
            label={i18nMessages.allModelsFilterText}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const { selectedIndex } = e.target;
              const selectedOption = e.target.options[selectedIndex];
              const selectedText = selectedOption.text;
              const selectedValue = selectedOption.value;

              if (availableVehicleTypes.includes(selectedValue as VehicleType)) {
                handleFilterChange('vehicleType', selectedValue);
              } else {
                handleFilterChange('bodyType', selectedValue);
              }
              trackTagClick(selectedText, selectedValue);
            }}
            value={selectedBodyType || selectedVehicleType}
            hideLabelOptional
          >
            {/* First, display vehicle types */}
            {availableVehicleTypes.includes(VehicleType.Bev) && (
              <option value={VehicleType.Bev} key="vehicle-type-bev">
                {electricTag}
              </option>
            )}
            {availableVehicleTypes.includes(VehicleType.Phev) && (
              <option value={VehicleType.Phev} key="vehicle-type-phev">
                {hybridTag}
              </option>
            )}

            {/* Now, display body types */}
            {bodyTypes.map((bodyType, index) => (
              <option value={bodyType[0]} key={`body-${bodyType[0]}-${index}`}>
                {bodyType[1]}
              </option>
            ))}
          </Select>
        </MobileFilterContainer>
      </FilterWrapper>
    );
  }
  return (
    <FilterWrapper>
      <TagGroupContainer>
        {/* initialy selected default tag */}
        <TagContainer
          data-testid="allModelsTag"
          role="button"
          tabIndex={0}
          onClick={() => resetFilter()}
          className={`${!selectedBodyType && !selectedVehicleType ? 'active' : ''}`}
        >
          <ContentWrapper>
            {!selectedBodyType && !selectedVehicleType && <Icon name="select" size="small" />}
            <Text variant="copy1">{truncateString(i18nMessages.allModelsFilterText)}</Text>
          </ContentWrapper>
        </TagContainer>
        {availableVehicleTypes.includes(VehicleType.Bev) && (
          <TagContainer
            key="electro"
            data-testid="electro"
            role="button"
            tabIndex={0}
            onClick={() => {
              handleFilterChange('vehicleType', 'BEV');
            }}
            className={`${selectedVehicleType === 'BEV' ? 'active' : ''}`}
          >
            <ContentWrapper>
              {selectedVehicleType === 'BEV' && <Icon name="select" size="small" />}
              <Text variant="copy1">{electricTag}</Text>
            </ContentWrapper>
          </TagContainer>
        )}

        {availableVehicleTypes.includes(VehicleType.Phev) && (
          <TagContainer
            key="hybrid"
            data-testid="hybrid"
            role="button"
            tabIndex={0}
            onClick={() => {
              handleFilterChange('vehicleType', 'PHEV');
            }}
            className={`${selectedVehicleType === 'PHEV' ? 'active' : ''}`}
          >
            <ContentWrapper>
              {selectedVehicleType === 'PHEV' && <Icon name="select" size="small" />}
              <Text variant="copy1">{hybridTag}</Text>
            </ContentWrapper>
          </TagContainer>
        )}
        {bodyTypes.map((bodyType, index) => (
          <TagContainer
            key={`${bodyType[0]}-${index}`}
            data-testid={bodyType[0]}
            role="button"
            tabIndex={0}
            onClick={() => {
              handleFilterChange('bodyType', bodyType[0]);
              trackTagClick(bodyType[1], bodyType[0]);
            }}
            className={`${selectedBodyType === bodyType[0] ? 'active' : ''}`}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter') {
                handleFilterChange('bodyType', bodyType[0]);
                trackTagClick(bodyType[1], bodyType[0]);
              }
            }}
          >
            <ContentWrapper>
              {selectedBodyType === bodyType[0] && <Icon name="select" size="small" />}
              <Text variant="copy1">{bodyType[1]}</Text>
            </ContentWrapper>
          </TagContainer>
        ))}
      </TagGroupContainer>
    </FilterWrapper>
  );
};
