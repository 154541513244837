import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getKeyboardFocusableElements, createVisibilityChecker } from '../Animations/slide';
import ArrowButton from '../ArrowButtons/ArrowButton';
import { useInitialState } from '../../context/InitialStateContext';
import { matchI18n } from '../../i18n';
import { useAppContext } from '../../context/Context';
import CarGroupCard from './Card';
import { Container, SliderContainer } from './CarGroupStyles';
import { MoxxCarlineGroup } from '../../../@types/MoxxTypes';
import { useTrackingManager } from '../../context/useTrackingManager';

const CarGroup: React.FC = () => {
  const { i18nMessages } = useInitialState();
  const { carlineGroups } = useInitialState();
  if (carlineGroups === undefined) {
    return null;
  }
  const { trackScrollOrSwipeEvent } = useTrackingManager();
  const { filteredCarlineGroups } = useAppContext();
  const { selectedCarGroupId, setSelectedCarGroupId, logger } = useAppContext();
  const [scrollState, setScrollState] = useState({
    scrolling: false,
    leftDisabled: true,
    rightDisabled: false,
    visibleCards: 1,
    hasScrolled: false,
  });
  const [carlineGroupsToDisplay, setCarlineGroupsToDisplay] = useState(
    filteredCarlineGroups || carlineGroups,
  );
  const minIndex = 0;

  const [maxIndex, setMaxIndex] = useState(carlineGroupsToDisplay.length - 1);
  const hasActiveFilter =
    filteredCarlineGroups && filteredCarlineGroups !== null && filteredCarlineGroups?.length > 0;
  const containerRef = useRef<HTMLUListElement>(null);
  const slideItemRefs = useMemo(
    () =>
      Array(carlineGroups.length)
        .fill(0)
        .map(() => React.createRef<HTMLLIElement>()),
    [],
  );

  const calculateVisibleCards = () => {
    if (containerRef.current && slideItemRefs[0]?.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const sliderItemWidth = slideItemRefs[0].current.offsetWidth;
      const calculatedVisibleCards = Math.floor(containerWidth / sliderItemWidth);

      setScrollState((prevState) => ({
        ...prevState,
        visibleCards: calculatedVisibleCards,
      }));
    }
  };

  const getCurrentLeftIndex = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      return Math.min(
        ...slideItemRefs
          .map(({ current }, index) => (createVisibilityChecker(container, current) ? index : -1))
          .filter((value) => value !== -1),
      );
    }
    return 0;
  }, [containerRef, slideItemRefs]);

  const scrollToIndex = useCallback(
    (index: number) => {
      const slideItemRef = slideItemRefs[index];
      if (containerRef.current && slideItemRef?.current) {
        const { left: containerLeft } = containerRef.current.getBoundingClientRect();
        const { left } = slideItemRef.current.getBoundingClientRect();
        containerRef.current?.scrollBy({
          left: Math.floor(left - containerLeft),
          behavior: 'smooth',
        });
      }
    },
    [containerRef, slideItemRefs],
  );

  const scrollFullyIntoView = (index: number) => {
    if (containerRef.current) {
      scrollToIndex(index);
    }
  };

  const scrollTo = useCallback(
    (direction: 'left' | 'right') => {
      if (containerRef.current) {
        const currentIndex = getCurrentLeftIndex();
        // Reset selectedCarGroupId when scrolling
        if (setSelectedCarGroupId) {
          setSelectedCarGroupId(undefined);
        }
        let newIndex: number = 0;
        if (direction === 'left') {
          // TODO: why -1
          newIndex = Math.max(minIndex, currentIndex - scrollState.visibleCards - 1);
        } else if (direction === 'right') {
          newIndex = Math.min(maxIndex, currentIndex + scrollState.visibleCards - 1);
        }
        setScrollState((prevState) => ({
          ...prevState,
          leftDisabled: newIndex === 0,
          rightDisabled: newIndex + prevState.visibleCards >= maxIndex,
        }));

        if (newIndex !== undefined && newIndex !== currentIndex) {
          if (!scrollState.hasScrolled) {
            // to fire scroll tracking only once
            trackScrollOrSwipeEvent();
            setScrollState((prevState) => ({
              ...prevState,
              hasScrolled: true,
            }));
          }
          scrollToIndex(newIndex);
        }
      }
    },
    [
      containerRef,
      getCurrentLeftIndex,
      scrollToIndex,
      scrollState.visibleCards,
      scrollState.hasScrolled,
      minIndex,
      maxIndex,
    ],
  );

  const amountCarlines = (amountModelle: number) => {
    const i18Key = amountModelle === 1 ? 'numberOfResult' : 'numberOfResults';
    return matchI18n(i18Key, i18nMessages, {
      value: amountModelle.toString(),
    });
  };

  const handleCardClick = (cargroupId: string) => {
    if (setSelectedCarGroupId) {
      const isSelected = cargroupId === selectedCarGroupId;
      setSelectedCarGroupId(isSelected ? undefined : cargroupId);
    } else {
      logger?.warn('setSelectedCarGroupId is undefined');
    }
  };

  // track mobile swipe event only once
  const handleTouchEnd = () => {
    const minSwipeDistance = 30; // in px
    const shouldTrackSwipe =
      containerRef.current &&
      containerRef.current.scrollLeft >= minSwipeDistance &&
      !scrollState.hasScrolled;

    if (shouldTrackSwipe) {
      trackScrollOrSwipeEvent();
      setScrollState((prevState) => ({ ...prevState, hasScrolled: true }));
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

      setScrollState((prevState) => ({
        ...prevState,
        leftDisabled: scrollLeft <= 0,
        rightDisabled: scrollLeft + clientWidth >= scrollWidth,
        scrolling: false,
      }));
    };

    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef, slideItemRefs, scrollState.visibleCards, maxIndex]);

  /**
   * a11y improvement - scroll to last card when focus is within
   * e.g. navigate back from preview button
   */
  useEffect(() => {
    const focusHandler = () => {
      if (!scrollState.rightDisabled) {
        scrollTo('right');
      }
    };

    const lastCard = slideItemRefs[slideItemRefs.length - 1]?.current;
    if (lastCard) {
      const focusableElements = getKeyboardFocusableElements(lastCard);

      focusableElements.forEach((element) => {
        element.addEventListener('focus', focusHandler);
      });
    }

    return () => {
      if (lastCard) {
        const focusableElements = getKeyboardFocusableElements(lastCard);

        focusableElements.forEach((element) => {
          element.removeEventListener('focus', focusHandler);
        });
      }
    };
  }, [scrollState.rightDisabled]);

  useEffect(() => {
    calculateVisibleCards(); // Initial calculation

    const resizeObserver = new ResizeObserver(() => {
      calculateVisibleCards(); // Recalculate on container resize
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [containerRef, slideItemRefs]);

  useEffect(() => {
    setCarlineGroupsToDisplay(filteredCarlineGroups || carlineGroups);
  }, [filteredCarlineGroups, carlineGroups, hasActiveFilter]);

  useEffect(() => {
    setMaxIndex(() => {
      const newMaxIndex = carlineGroupsToDisplay.length;
      return newMaxIndex;
    });
    // reset card position
    if (containerRef && containerRef.current) {
      containerRef.current.scrollLeft = 0;
    }
  }, [carlineGroupsToDisplay.length]);

  useEffect(() => {
    setScrollState((prevState) => {
      const leftIndex = getCurrentLeftIndex();

      return {
        ...prevState,
        leftDisabled: leftIndex === 0,
        rightDisabled: leftIndex + scrollState.visibleCards >= maxIndex,
        scrolling: false,
      };
    });
  }, [maxIndex]);

  return (
    <Container>
      <SliderContainer
        ref={containerRef}
        onTouchEnd={handleTouchEnd}
        data-testid="slider-container"
      >
        {carlineGroupsToDisplay?.map((carlineGroup: MoxxCarlineGroup, index: number) => {
          return (
            <CarGroupCard
              key={`${carlineGroup.name}-${index}`} // eslint-disable-line react/no-array-index-key
              ref={slideItemRefs[index]}
              carlineGroup={carlineGroup}
              isSelected={carlineGroup.id === selectedCarGroupId}
              dataTestId={`slider-item-${index}`}
              onClick={() => {
                handleCardClick(carlineGroup.id);
                if (
                  containerRef &&
                  containerRef.current &&
                  slideItemRefs[index] &&
                  slideItemRefs[index].current
                ) {
                  const isVisibleItem = createVisibilityChecker(
                    containerRef.current,
                    slideItemRefs[index].current,
                  );
                  if (!isVisibleItem) {
                    scrollFullyIntoView(index);
                  }
                }
              }}
              amountCarlines={amountCarlines(carlineGroup.carlines.length)}
            />
          );
        })}
      </SliderContainer>
      <ArrowButton
        data-testid="arrow-button-left"
        onClick={() => {
          scrollTo('left');
        }}
        disabled={scrollState.leftDisabled || scrollState.scrolling}
        direction="left"
        variant="icon-tertiary"
      />
      <ArrowButton
        data-testid="arrow-button-right"
        onClick={() => {
          scrollTo('right');
        }}
        disabled={scrollState.rightDisabled || scrollState.scrolling}
        direction="right"
        variant="icon-tertiary"
      />
    </Container>
  );
};

export default CarGroup;
