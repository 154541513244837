import styled from 'styled-components';

export const FilterWrapper = styled.div`
  margin-inline: var(${(props) => props.theme.responsive.spacing.pageMargin});
  margin-bottom: var(${(props) => props.theme.responsive.spacing.l});
`;

export const MobileFilterContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: 30%;
  }
`;

export const TagGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(${(props) => props.theme.responsive.spacing.xs});
`;

export const TagContainer = styled.div`
  cursor: pointer;
  border: 1px solid var(${({ theme }) => theme.colors.base.grey['15']});
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  padding: var(${(props) => props.theme.responsive.spacing.xxs})
    var(${(props) => props.theme.responsive.spacing.s});

  &.active {
    background: var(${({ theme }) => theme.colors.base.grey['5']});
    border: 1px solid var(${({ theme }) => theme.colors.base.grey['5']});
  }
  &.disabled {
    background: var(${({ theme }) => theme.colors.base.white});
    border: 1px solid var(${({ theme }) => theme.colors.base.grey['5']});
    p {
      color: var(${({ theme }) => theme.colors.base.grey['10']}) !important;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
`;
