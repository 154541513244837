// TODO: consider moving into shared package

import type {
  CarlineAvailableStock,
  CarlineConsumptionAndEmission,
  CarlineGroup,
  CarlineStructureCarline,
  CarlineTrimlineFinanceablePrices,
  Maybe,
  Stock,
} from '../../web/generated/onegraph';

import type {
  MoxxAvailableStock,
  MoxxCarline,
  MoxxCarlineGroup,
  MoxxConsumptionAndEmission,
  MoxxMinPrice,
  MoxxStock,
} from '../../@types/MoxxTypes';

const mapToMoxxMinPrice = ({
  minPrice,
}: CarlineTrimlineFinanceablePrices): MoxxMinPrice | undefined => {
  if (minPrice === undefined || minPrice === null) {
    return undefined;
  }
  return {
    value: minPrice.value,
  };
};

const mapToConsumptionAndEmission = ({
  summary,
}: CarlineConsumptionAndEmission): MoxxConsumptionAndEmission | undefined => {
  if (summary === undefined || summary === null) {
    return undefined;
  }
  return {
    consumption: summary.consumption,
    emission: summary.emissionCO2,
    co2Class: summary.co2Class || '',
    dischargedCO2Class: summary.dischargedCO2Class || '',
    dischargedConsumption: summary.dischargedConsumption || '',
  };
};

const mapToMoxxStock = (stock?: Maybe<Stock>): MoxxStock | undefined => {
  if (stock === undefined || stock === null) {
    return undefined;
  }
  return {
    amount: stock.amount,
    url: stock.url,
  };
};

const mapToMoxxAvailableStock = (
  availableStock?: Maybe<CarlineAvailableStock>,
): MoxxAvailableStock | undefined => {
  if (availableStock === undefined || availableStock === null) {
    return undefined;
  }
  return {
    newCars: mapToMoxxStock(availableStock.newCars),
    usedCars: mapToMoxxStock(availableStock.usedCars),
  };
};

const mapToMoxxCarline = ({
  identifier: { id },
  name,
  vehicleType,
  fuelTypes,
  bodyTypes,
  prices,
  exteriorViews,
  consumptionAndEmission,
  availableStock,
  isFake,
  markAsNew,
  links,
}: CarlineStructureCarline): MoxxCarline => {
  const discoverLinkUrl = links?.carlineStartPage?.url;
  const configureLinkUrl = links?.configuratorPage?.url;
  return {
    id,
    name,
    vehicleType: vehicleType !== null ? vehicleType : undefined,
    fuelTypes,
    bodyTypes: bodyTypes?.filter((b): b is string => b !== null) ?? [],
    minPrice: !isFake ? mapToMoxxMinPrice(prices) : undefined,
    image: exteriorViews?.beautyFront,
    consumptionAndEmission: mapToConsumptionAndEmission(consumptionAndEmission),
    availableStock: mapToMoxxAvailableStock(availableStock),
    discoverLinkUrl: discoverLinkUrl !== null ? discoverLinkUrl : undefined,
    configureLinkUrl: configureLinkUrl !== null ? configureLinkUrl : undefined,
    isFake,
    markAsNew,
  };
};

export const mapToMoxxCarlineGroup = ({
  identifier: { id },
  name,
  carlines,
}: CarlineGroup): MoxxCarlineGroup => {
  return {
    id,
    name,
    image: carlines.length > 0 ? carlines[0].exteriorViews?.beautyFront : undefined,

    amountCarlines: carlines.length ?? 0,
    carlines: carlines.map((carline) => mapToMoxxCarline(carline)),
  };
};
