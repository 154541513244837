import { HeadlessContent, Content } from '../../@types/EditorContentTypes';

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalizedContent: Content = {
      priceDisplayOption: {
        showBasePrice: content.fields.priceDisplayOption_showBasePrice,
        showVat: content.fields.priceDisplayOption_showVat,
      },
      caeDisplayOption: {
        showCae: content.fields.caeDisplayOption_showCae,
      },
      ctaDisplayOptions: {
        showConfigureCTA: content.fields.ctaDisplayOptions_showConfigureCTA,
        showNewAndUsedCarsCTA: content.fields.ctaDisplayOptions_showNewAndUsedCarsCTA,
      },
      priceFootnotes: content.fields.priceFootnotes,
    };
    return normalizedContent;
  }
  return content as Content;
};
